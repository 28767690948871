<template>
  <div>
    <el-row class="dash-board">
      <el-button type="primary" @click="addRole" v-permission="['Jurisdiction:add']" class="attendSel">+添加角色</el-button>
    </el-row>
    <el-row class="bg">
    <el-table
      :data="tableData"
      style="width: 100%;">
      <el-table-column
        label="角色名称"
        align="center"
        width="300">
        <template slot-scope="scope">
          <span>{{ scope.row.roleName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="角色描述"
        align="center"
        width="300">
        <template slot-scope="scope">
          <span>{{ scope.row.roleDescribe }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" v-permission="['Jurisdiction:role']" @click="handleRole(scope.row.roleId)">设置权限</el-button>
          <el-button type="primary" size="mini" v-permission="['Jurisdiction:edit']" @click="editleRole(scope.row.roleId)" v-if="scope.row.roleId > 3">修 改</el-button>
          <el-button type="danger" size="mini" v-permission="['Jurisdiction:delete']" @click="delRole(scope.row.roleId)" v-if="scope.row.roleId > 3">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-row>
    <!-- 添加角色 -->
    <el-dialog title="添加角色" :visible.sync="roleVisible" width="40%">
      <el-form  :model="roleForm" ref="roleForm" :rules="rules3" class="demo-ruleForm">
        <el-form-item label="角色名称" prop="roleName" :label-width="formLabelWidth">
          <el-input v-model="roleForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" :label-width="formLabelWidth">
          <el-input v-model="roleForm.roleDescribe"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRoleBt('roleForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改角色 -->
    <el-dialog title="修改角色" :visible.sync="editleRoleVisible" width="40%">
      <el-form  :model="editleRoleForm" ref="editleRoleForm" :rules="rules4" class="demo-ruleForm">
        <el-form-item label="角色名称" prop="roleName" :label-width="formLabelWidth">
          <el-input v-model="editleRoleForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" :label-width="formLabelWidth">
          <el-input v-model="editleRoleForm.roleDescribe"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editleRoleVisible = false">取 消</el-button>
        <el-button type="primary" @click="editleRoleBt('editleRoleForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 分配权限 -->
    <el-dialog
      title="分配权限"
      :visible.sync="distributionVisible"
      width="700"
      center>
      <el-col>
        <el-tree
          :props="defaultProps"
          :data="tableListFir"
          show-checkbox
          default-expand-all
          ref="treeData"
          node-key="id"
          @check-change="handleSelectionChange"
          :default-checked-keys="resourceCheckedKey"
        >
          <div class="custom-tree-node clearfix" slot-scope="{node, data}">
          <span class="table_info_node clearfix">
              <span class="table_info_item">{{data.title}}</span>
              <el-tag v-if="data.perms" type="success" size="mini">{{ data.perms }}</el-tag>
          </span>
          </div>
        </el-tree>
      </el-col>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
  import treeList from '@/router/tree.json'
  import { roleList, roleUrlById, updUrlByAdmin, addRole, selSysRole, editleRole, delRole } from '@/api/training.js'
  export default {
    name: 'Jurisdiction',
    data() {
      return {
        tableData: [],
        distributionVisible: false,
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        // tree: [],
        tableListFir: [], // 该数组是存储 要渲染的树形结构数据
        multipleSelection: [], // 该数组是选中的节点(半选的节点和全选的节点全都存储在这个数组)
        resourceCheckedKey: [], // 该数组回显数据根据所对应的ID  进行默认勾选 (:default-checked-keys 这个) 和 (node-key="id") 这个两个并存
        select: [],
        tijiao: [],
        roleForm: {
          roleName: '',
          roleDescribe: ''
        },
        editleRoleForm: {
          roleName: '',
          roleDescribe: ''
        },
        roleVisible: false,
        editleRoleVisible: false,
        rules3: {
          roleName: [
            { required: true, message: '角色名称不能为空', trigger: 'change' }
          ]
        },
        rules4: {
          roleName: [
            { required: true, message: '角色名称不能为空', trigger: 'change' }
          ]
        },
        formLabelWidth: '120px'
      }
    },
    mounted() {
      this.tableDataFir()
      this.roleList()
    },
    methods: {
      roleList() { // 权限用户列表
        roleList().then(res => {
          if (res.data.code === '200') {
            this.tableData = res.data.data
          }
        })
      },
      addRole() { // 新增角色
        this.roleVisible = true
      },
      addRoleBt(roleForm) {
        this.$refs[roleForm].validate((valid) => {
          if (valid) {
              const sysRole = {
                roleName: this.roleForm.roleName,
                roleDescribe: this.roleForm.roleDescribe
              }
              addRole(sysRole).then(res => {
                this[roleForm] = {}
                if (res.data.code === '0') {
                  this.$notify({
                    title: '成功',
                    message: '新增成功。',
                    type: 'success'
                  })
                  this.roleVisible = false
                  this.roleList()
                }
            })
          }
        })
      },
      editleRole(roleId) { // 修改角色
        this.editleRoleVisible = true
        this.id = roleId
        selSysRole(this.id).then(res => {
          if (res.data.code === '200') {
            this.editleRoleForm = res.data.data
          }
        })
      },
      editleRoleBt(editleRoleForm) { // 提交修改角色
        this.$refs[editleRoleForm].validate((valid) => {
          if (valid) {
            const sysRole = {
              roleId: this.id,
              roleName: this.editleRoleForm.roleName,
              roleDescribe: this.editleRoleForm.roleDescribe
            }
            editleRole(sysRole).then(res => {
              this[editleRoleForm] = {}
              if (res.data.code === '0') {
                this.$notify({
                  title: '成功',
                  message: '修改成功。',
                  type: 'success'
                })
                this.editleRoleVisible = false
                this.roleList()
              }
            })
          }
        })
      },
      delRole(roleId) { // 删除角色
        this.id = roleId
        this.$confirm('确定删除此角色, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delRole(this.id).then(res => {
            if (res.data.code === '200') {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.roleList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      handleRole(roleId) { // 分配权限
        this.id = roleId
        roleUrlById(this.id).then(res => {
          if (res.data.code === '200') {
            // this.select.push(res.data.data)
            this.select = res.data.data
            res.data.data.forEach(value => {
              this.resourceCheckedKey.push(value)
            })
            this.$nextTick(() => {
              this.$refs.treeData.setCheckedKeys(this.resourceCheckedKey)
            })
          }
          console.log(this.select)
        })
        this.distributionVisible = true
      },
      submitForm() {
        // 获取到选中的值
        var tt = this.$refs.treeData.getCheckedKeys()
        tt.filter((item) => {
          if (item) {
            this.tijiao.push(item)
          }
        })
        // var fruitsObj = { ...this.tijiao }
        console.log(tt)
        const updSysAuth = {
          id: this.id,
          urlList: this.tijiao
        }
        console.log(updSysAuth)
        updUrlByAdmin(updSysAuth).then(res => {
          if (res.data.code === '200') {
            this.$notify({
              title: '成功',
              message: '权限分配成功。',
              type: 'success'
            })
            this.distributionVisible = false
            history.go(0) // 刷新当前页面
          }
        })
      },
      // 渲染树形数据
      tableDataFir() {
        this.tableListFir = treeList
      },
      // el-tree 复选框默认选中事件
      handleSelectionChange(val) {
        this.multipleSelection = this.$refs.treeData.getCheckedNodes()
        // this.multipleSelection  这个数组用来存储所有半选的节点和全选的节点  用于保存的时候将数据传给后台
        // 将半选和全选的节点全都存储在一个数组里面   concat 方法是合并(全选和半选)两个数组
        // this.multipleSelection = this.$refs.treeData.getHalfCheckedNodes().concat(this.$refs.treeData.getCheckedNodes())
      }
      // 遍历找出所有子节点
      // findAllChildren(data, arr) {
      //  data.forEach((item, index) => {
      // console.log(item)
      // if (item.children.length !== 0) {
      //     this.findAllChildren(item.children, arr)
      // } else if (item.validSign === '1') {
      // validSign 这个字段是后台用来判断前台是否默认选中的字段  validSign == 1 是选中  等于 0 是非选中 (根据你们后台实际参数进行修改)
      // 然后我们将 validSign == '1' 的所有子节点全部存储在arr 里面
      //     arr.push(item.id)
      // }
      //  })
      // }
    }
  }
</script>

<style scoped>
  .dash-board {
    display: flex;
    /*left: 10px;*/
    background-color: white;
    height: 60px;
  }
  .attendSel{
    position: absolute;
    left:20px;
    top: 15px;
  }
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
</style>
